import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { InfoMedia, FormFive } from '../../components';

const ContactTwo = ({ ...restProps }) => {
  const [currentEmail, setCurrentEmail] = useState("creators@datumailabs.com");

  const setEmail = (mail) => {
    setCurrentEmail(mail)
  };

  return (
    <div {...restProps}>
      <div className="container">
        <Tab.Container id="contactTab" defaultActiveKey='tabno1'>
          <Nav className="contact-tab-menu">
            <Nav.Link eventKey="tabno1" onClick={() => setEmail("creators@datumailabs.com")}>
              <span className="btn-img"><img src="images/contact/UK.jpg" alt="tabicon" /></span>
              <span className="btn-title h6">Datum AI Labs Ltd, UK</span>
              <span className="btn-text">Croydon, London</span>
            </Nav.Link>
            <Nav.Link eventKey="tabno2" onClick={() => setEmail("usteam@datumailabs.com")}>
              <span className="btn-img"><img src="images/contact/US.jpg" alt="tabicon" /></span>
              <span className="btn-title h6">Datum AI Labs, USA</span>
              <span className="btn-text">Arkansas, USA</span>
            </Nav.Link>
            <Nav.Link eventKey="tabno3" onClick={() => setEmail("creators@datumailabs.com")}>
              <span className="btn-img"><img src="images/contact/IN.jpeg" alt="tabicon" /></span>
              <span className="btn-title h6">Datum AI Labs, India</span>
              <span className="btn-text">Chennai, India</span>
            </Nav.Link>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="tabno1">
              <div className="row">
                <div className="col-lg-6 mb-30">
                  <div className="contact-box">
                    <h3 className="contact-box__title h4 mb-5">UK Office Address</h3>
                    {/* <p className="contact-box__text">Completely recaptiualize 24/7 communities via standards compliant metrics whereas web-enabled content</p> */}
                    <InfoMedia
                      icon="fal fa-phone-alt"
                      title="Phone Number & Email"
                      info={<><a className='d-block' href="tel:+447908771454">+44 7908771454</a><a className='d-block' href="mailto:creators@datumailabs.com">creators@datumailabs.com</a></>}
                    />
                    <InfoMedia
                      icon="far fa-map-marker-alt"
                      title="Our Office Address"
                      addr1="Weatherill House Business Centre, New South Quarter,"
                      addr2="23 Whitestone Way, Croydon CR04WF,"
                      addr3="Private Limited Company (Registration Number 14935337)"
                      country="UK"
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-30">
                  <div className="contact-box">
                    <h3 className="contact-box__title h4">Leave a Message</h3>
                    <p className="contact-box__text">We’re Ready To Help You</p>
                    <FormFive crntMail={currentEmail} />
                  </div>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="tabno2">
              <div className="row">
                <div className="col-lg-6 mb-30">
                  <div className="contact-box">
                    <h3 className="contact-box__title h4 mb-5">USA Office Address</h3>
                    {/* <p className="contact-box__text">Completely recaptiualize 24/7 communities via standards compliant metrics whereas web-enabled content</p> */}
                    <InfoMedia
                      icon="fal fa-phone-alt"
                      title="Phone Number & Email"
                      info={<><a className='d-block' href="tel:+14083932054">+1 (408) 393-2054</a><a className='d-block' href="mailto:creators@datumailabs.com">creators@datumailabs.com</a></>}
                    />
                    <InfoMedia
                      icon="far fa-map-marker-alt"
                      title="Our Office Address"
                      addr1="751, Dixon Street,"
                      addr2="Centerton 72719, Arkansas,"
                      country="USA"
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-30">
                  <div className="contact-box">
                    <h3 className="contact-box__title h4">Leave a Message</h3>
                    <p className="contact-box__text">We’re Ready To Help You</p>
                    <FormFive crntMail={currentEmail} />
                  </div>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="tabno3">
              <div className="row">
                <div className="col-lg-6 mb-30">
                  <div className="contact-box">
                    <h3 className="contact-box__title h4 mb-5">India Office Address</h3>
                    {/* <p className="contact-box__text">Completely recaptiualize 24/7 communities via standards compliant metrics whereas web-enabled content</p> */}
                    <InfoMedia
                      icon="fal fa-phone-alt"
                      title="Phone Number & Email"
                      info={<><a className='d-block' href="tel:+919841162317">+91 9841162317</a><a className='d-block' href="mailto:creators@datumailabs.com">creators@datumailabs.com</a></>}
                    />
                    <InfoMedia
                      icon="far fa-map-marker-alt"
                      title="Our Office Address"
                      addr1="F2C, Tranquil Acres Phase II, 200Ft MMRD Road,"
                      addr2="Kovilambakkam, Chennai 600117"
                      country="India"
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-30">
                  <div className="contact-box">
                    <h3 className="contact-box__title h4">Leave a Message</h3>
                    <p className="contact-box__text">We’re Ready To Help You</p>
                    <FormFive crntMail={currentEmail} />
                  </div>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  )
};

export default ContactTwo;
